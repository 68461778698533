import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {CustomPropertyReadComponent} from "@shared/components/custom-property-read/custom-property-read.component";
import {ReadInputTextComponent} from "@shared/components/custom-property-read/inputs/text/read-input-text.component";
import {HeaderDesignEnum} from "@shared/components/custom-property/read/enums/header-design.enum";
import {
  CustomPropertySetFormatted
} from "@shared/components/custom-property/interfaces/custom-property-set-formatted.interface";

@Component({
  selector: 'app-custom-read-property-sets',
  templateUrl: './custom-property-sets-read.component.html',
  styleUrls: ['./custom-property-sets-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe, UserHtmlComponent, AttachmentsComponent, CustomPropertyReadComponent, ReadInputTextComponent],

})
export class CustomPropertySetsReadComponent implements OnInit {
  @Input() propertySetsDetail: CustomPropertySetFormatted[];
  @Input() headerDesign: HeaderDesignEnum;

  constructor() {
    this.propertySetsDetail = [];
    this.headerDesign = HeaderDesignEnum.Header;
  }

  ngOnInit() {
  }

  protected readonly HeaderDesignEnum = HeaderDesignEnum;
}
