<form [formGroup]="customValues" (ngSubmit)="formModified()" (change)="formModified()">
  <ng-container *ngIf="customPropertySets" formGroupName="CustomValues">
    <ng-container *ngFor="let groupSortedIndex of getSortedIndexes()">

      <ion-card [ngClass]="{'margin-remove': removeMargin}" [formGroupName]="getGroupNameByIndex(groupSortedIndex)">
        <ion-card-header>{{ getPropertyName(groupSortedIndex) }}</ion-card-header>
        <ion-card-content class="space-reduce"
                          *ngFor="let controlName of getControlNames(getGroupNameByIndex(groupSortedIndex)); let controlIndex = index">
          <app-custom-property
            [inputProperty]="getProperty(groupSortedIndex, controlName)"
            [formControl]="getControlFromGroup(getGroupNameByIndex(groupSortedIndex), controlName)"
            [index]="controlIndex">
          </app-custom-property>
        </ion-card-content>
      </ion-card>

    </ng-container>
  </ng-container>
</form>
