import {Injectable} from "@angular/core";
import {
  IssueContentType,
  IssueTemplateListDto,
  IssueType,
  ResourceProfileDto,
  StereotypeDto,
} from "@server-models";
import {
  CustomPropertyStructureFormatted
} from "@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {DateHelperService} from "@shared/services/date-helper.service";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";
import {SubmitIssueDtoType} from "@shared/components/issues/types/submit-issue-dto.type";
import {StereotypeBaseService} from "@shared/services/stereotype/stereotype-base.service";
import {
  AdditionalDetails
} from "@shared/components/custom-property/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export abstract class IssuesBaseService {

  constructor(
    protected _fb: FormBuilder,
    protected _translate: TranslateService,
    protected _dateHelperService: DateHelperService,
    protected _stereotypeBaseService: StereotypeBaseService
  ) {
  }

  abstract formatReadDetailData(issueDto: IssueDtoType, issueType: IssueType, stereotypeDto?: StereotypeDto): CustomPropertyStructureFormatted;
  /**
   * @name getAdditionalFormattedData
   * @description
   * abstract method to delegate the task of add additional data to children
   * this method is use to add additional data into the formatted object
   * @memberof IssuesBaseService
   * @param issueDto
   */
  abstract getAdditionalFormattedData(issueDto: IssueDtoType): Partial<AdditionalDetails>;

  abstract prepareFormIssueDto(
    form: FormGroup,
    templatePreviewDto: unknown,
    resourceDto?: ResourceProfileDto,
    tenantId?: number
  ): SubmitIssueDtoType;

  /**
   * @name filterValidIssueContentTypes
   * @description
   * check if the contentType of the templateList is one of the IssueContentType key objects (types included)
   * @memberof IssuesBaseService
   * @param templateList
   * @protected
   */
  filterValidIssueContentTypes(templateList: IssueTemplateListDto[]): IssueTemplateListDto[] {
    return templateList.filter(b => b.contentType !== undefined && Object.values(IssueContentType).includes(b.contentType));
  }

}
