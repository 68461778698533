import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import type {CustomDefaultCompleteDto} from "@server-models";

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputDropdownComponent
    }
  ]
})
export class InputDropdownComponent implements OnChanges, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Input() options!: CustomDefaultCompleteDto[];
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;

  value: string = '';

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor() {
    this.formControl = new FormControl();
    this.isRequired = false;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formControl']) {
      this._setFormValue()
    }
  }

  private _setFormValue() {
    if (this.formControl && !this.formControl.value && this.options) {
      const selectedOption = this.options.find(option => option.isSelected);
      if (selectedOption) {
        this.formControl.setValue(selectedOption.value);
      } else {
        this.formControl.setValue(undefined);
      }

    }
    if (this.formControl && this.formControl.value) {
      if (typeof this.formControl.value === 'number') {
        this.formControl.setValue(this.formControl.value);
      } else {
        const optionValue = this.options.find(option => option.value === this.formControl.value);
        this.formControl.setValue(optionValue!.customValueId);
      }
    }
  }
}
