import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {CustomPropertyReadComponent} from "@shared/components/custom-property-read/custom-property-read.component";
import {
  CustomPropertyStructureFormatted
} from "@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {BaseIssuesState} from "@shared/components/issues/store/base-issues.state";
import {IssueType} from "@server-models";
import {
  BaseIssueReadTicketComponent
} from '@shared/components/issues/components/base-issue-read/components/base-issue-read-ticket/base-issue-read-ticket.component';
import {
  BaseIssueReadMissionComponent
} from '@shared/components/issues/components/base-issue-read/components/base-issue-read-mission/base-issue-read-mission.component';
import {
  BaseIssueReadMessageComponent
} from '@shared/components/issues/components/base-issue-read/components/base-issue-read-message/base-issue-read-message.component';
import {
  BaseIssueReadWinterhalterServiceCallComponent
} from '@shared/components/issues/components/base-issue-read/components/winterhalter/read-service-call/read-service-call.component';
import {
  BaseIssueReadWinterhalterProductOrderComponent
} from '@shared/components/issues/components/base-issue-read/components/winterhalter/read-product-order/read-product-order.component';
import {
  ReadWinterhalterOrderInfoComponent
} from '@shared/components/issues/components/base-issue-read/components/winterhalter/read-winterhalter-order-info/read-winterhalter-order-info.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-base-issue-read',
  templateUrl: './base-issue-read.component.html',
  styleUrls: ['./base-issue-read.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    CustomPropertyReadComponent,
    BaseIssueReadTicketComponent,
    BaseIssueReadMissionComponent,
    BaseIssueReadMessageComponent,
    BaseIssueReadWinterhalterServiceCallComponent,
    BaseIssueReadWinterhalterProductOrderComponent,
    ReadWinterhalterOrderInfoComponent
  ]

})
export class BaseIssueReadComponent implements OnInit {
  id: number;
  isLoading$!: Observable<boolean>;
  detailDataFormatted$!: Observable<CustomPropertyStructureFormatted>;
  protected readonly IssueType = IssueType;
  isWinterhalterIssue$!: Observable<boolean>;

  constructor(
    private _store: Store<BaseIssuesState>
  ) {
    this.id = NaN;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectIsLoading));
    this.detailDataFormatted$ = this._store.pipe(select(BaseIssuesSelectors.selectDetailDataFormatted));
    this.isWinterhalterIssue$ = this.detailDataFormatted$.pipe(map(detailData => this._isWinterhalterIssue(detailData)));
  }

  /**
   * @name back
   * @description
   * navigates to previous page issues
   * @memberof BaseIssueReadComponent
   */
  back(): void {
    this._store.dispatch(BaseIssuesActions.navigateToIssues());
  }

  private _isWinterhalterIssue(detailData: CustomPropertyStructureFormatted): boolean {
    const issueType = detailData.type;
    return issueType === IssueType.WinterhalterProductOrder || issueType === IssueType.WinterhalterServiceCall;
  }
}
