import {Injectable} from "@angular/core";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto, IssueType, MissionEditorSelectorKind, MissionIssueDto,
  MissionIssuePreviewDto, PrioritySimpleDto, ResourceProfileDto, StereotypeDto,
  StereotypeUsageDto,
  SubmitMissionIssueDto
} from "@server-models";
import { CustomPropertyStructureFormatted } from "@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {IssueBaseStereotypeService} from "@shared/components/issues/services/stereotype/stereotype.service";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";
import {
  AdditionalDetails
} from "@shared/components/custom-property/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseMissionService extends IssueBaseStereotypeService {

  override formatReadDetailData(issueDtoType: IssueDtoType, issueType: IssueType, stereotypeDto?: StereotypeDto | undefined): CustomPropertyStructureFormatted {
    const issueDtoTypeMission = issueDtoType as MissionIssueDto;
    return this._stereotypeBaseService.customPropertiesFormattedFromStereotype(
      issueDtoTypeMission.issueId!,
      issueDtoTypeMission.title!,
      issueType,
      issueDtoTypeMission.customPropertyValues!,
      stereotypeDto!,
      issueDtoTypeMission,
      (dto) => this.getAdditionalFormattedData(dto)
    );
  }

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: MissionIssuePreviewDto, resourceDto?: ResourceProfileDto): SubmitMissionIssueDto {
    const priorityFormGroup = formGroup.get('Priority') as FormGroup;
    const customValuesFromGroup = formGroup.get('CustomValues') as FormGroup;

    const priority = this.getPriority(templatePreviewDto, priorityFormGroup);

    const customPropertyValues = this._stereotypeBaseService.prepareCustomValues(customValuesFromGroup); // to build the customValues
    const issueStereotype: StereotypeUsageDto = templatePreviewDto.issueStereotype!;
    const customPropertySets: CustomSetReferenceDto[] = issueStereotype.customPropertySets!;

    const sortedCustomPropertySets: CustomSetReferenceDto[] = this._stereotypeBaseService.sortCustomPropertySets(customPropertySets);
    const stereotypeCustomProperties: CustomPropertyDto[] = this._stereotypeBaseService.flattenCustomProperties(sortedCustomPropertySets);
    const mutateCustomPropertyValues: FilledCustomValueDto[] = this._stereotypeBaseService.prepareCustomPropertyValues(customPropertyValues, issueStereotype.tenantId!, stereotypeCustomProperties);

    return {
      title: templatePreviewDto.name,
      tenantId: issueStereotype?.tenantId,
      stereotypeId: issueStereotype?.stereotypeId,
      stereotypeRowVersion: issueStereotype?.rowVersion,
      customPropertyValues: mutateCustomPropertyValues,
      priority,
      resource: resourceDto
    };
  }

  getPriority(templatePreviewDto: MissionIssuePreviewDto, formGroup: FormGroup): PrioritySimpleDto | undefined {
    let priority;
    const priorityList = templatePreviewDto.priorities!;

    if (templatePreviewDto.editorSelectorKind === MissionEditorSelectorKind.PrioritySearch) {
      if (priorityList.length === 1) {
        priority = priorityList[0];
      } else if (formGroup) {
        priority = this.findPriorityValue(formGroup, priorityList);
      }
    }

    return priority;
  }

  findPriorityValue(priorityForm: FormGroup, priorities: PrioritySimpleDto[]): PrioritySimpleDto {
    const valueToFind = priorityForm.value;
    return priorities.find(p => p?.priorityId === valueToFind)!
  }

  getAdditionalFormattedData(issueDto: MissionIssueDto): Partial<AdditionalDetails> {
    const missionDetail = issueDto.mission;
    return {
      mission: missionDetail
    };
  }
}
