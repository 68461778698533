import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { BaseIssuesSelectors } from '@shared/components/issues/store/base-issues.selectors-type';
import { BaseIssuesState } from '@shared/components/issues/store/base-issues.state';
import { ReadInputTextComponent } from '@shared/components/custom-property-read/inputs/text/read-input-text.component';
import { map } from 'rxjs/operators';
import { WinterhalterOperationStatus, WinterhalterOrderDto } from '@server-models';
import { ReadInputDateComponent } from '@shared/components/custom-property-read/inputs/date/read-input-date.component';
import {
  CustomPropertyStructureFormatted
} from '@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface';

export const winterhalterOrderStateEnumOptions = [
  {value: WinterhalterOperationStatus.Created, label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.CREATED' },
  {value: WinterhalterOperationStatus.Instructed, label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.INSTRUCTED'},
  {value: WinterhalterOperationStatus.Transition, label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.TRANSITION'},
  {value: WinterhalterOperationStatus.Done, label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.DONE'},
  {value: WinterhalterOperationStatus.Canceled, label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.CANCELED'}
]

@Component({
  selector: 'app-base-issue-read-winterhalter-order-info',
  templateUrl: './read-winterhalter-order-info.component.html',
  styleUrls: ['./read-winterhalter-order-info.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ReadInputTextComponent, ReadInputDateComponent]

})
export class ReadWinterhalterOrderInfoComponent implements OnInit {

  detailDataFormatted$!: Observable<CustomPropertyStructureFormatted>;
  winterhalterOrder$?: Observable<WinterhalterOrderDto | undefined> = of();
  winterhalterStateValue$?: Observable<string | undefined> = of();
  isLoading$!: Observable<boolean>;

  constructor(
    private _store: Store<BaseIssuesState>
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectIsLoading));

    this.detailDataFormatted$ = this._store.pipe(select(BaseIssuesSelectors.selectDetailDataFormatted))

    this.winterhalterOrder$ = this.detailDataFormatted$.pipe(
      map(detailData => detailData?.additionalDetails?.winterhalter?.winterhalterOrder)
    );

    this.winterhalterStateValue$ = this.winterhalterOrder$.pipe(
      map(order => winterhalterOrderStateEnumOptions.find(option => option.value === (order?.operations ?? [])[0]?.status)?.label)
    );
  }
}
