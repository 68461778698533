import {createAction, props} from "@ngrx/store";
import {AttachmentsFileAction} from "@shared/components/attachments/interfaces/attachments-file-action.interface";
import {
  AttachmentsFileRequestAction
} from "@shared/components/attachments/interfaces/attachments-file-request-action.interface";
import {OpenOptions} from "@capacitor/browser/dist/esm/definitions";
import {
  AttachmentKnowledgeArticle
} from "@shared/components/attachments/interfaces/attachment-knowledge-article.interface";
import {
  AttachmentKnowledgeArticleResponse
} from "@shared/components/attachments/interfaces/attachment-knowledge-article-response.interface";

export const getFile = createAction(
  '[Attachments] Get File Start',
  props<AttachmentsFileRequestAction>()
);

export const getFileSuccess = createAction(
  '[Attachments] Get File Success',
  props<AttachmentsFileAction>()
);

export const getFileFail = createAction(
  '[Attachments] Get File Fail',
  props<{ error: any }>()
);

export const openLink = createAction(
  '[Attachments] Open Link Start',
  props<{id: number, url: string}>()
);

export const openLinkSuccess = createAction(
  '[Attachments] Open Link Success',
  props<{id: number, url: string}>()
);

export const openLinkFail = createAction(
  '[Attachments] Open Link Fail',
  props<{error: any}>()
);

export const getKnowledgeArticle = createAction(
  '[Attachments] Get KnowledgeArticle Start',
  props<AttachmentKnowledgeArticle>()
);

export const getKnowledgeArticleSuccess = createAction(
  '[Attachments] Get KnowledgeArticle Success',
  props<AttachmentKnowledgeArticleResponse>()
);

export const getKnowledgeArticleFail = createAction(
  '[Attachments] Get KnowledgeArticle Fail',
  props<{error: any}>()
);
