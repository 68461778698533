/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ControlReceiverType {
    Base = '0',
    EmailReceiver = 1,
    ContactReceiver = 2,
    FunctionReceiver = 3,
}
