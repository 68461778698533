import {Injectable} from "@angular/core";
import {CustomPropertyType} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class CustomPropertyService {
  private _customTypes: Map<string, CustomPropertyType> = new Map();

  /**
   * @name addTypeProperty
   * @description
   * add a property to map customType
   * @memberof CustomPropertyService
   * @param key
   * @param type
   */
  addTypeProperty(key: string, type: CustomPropertyType): void {
    this._customTypes.set(key, type);
  }

  /**
   * @name getTypeProperty
   * @description
   * returns a property to map customType by key
   * @memberof CustomPropertyService
   * @param key
   * @returns {CustomPropertyType}
   */
  getTypeProperty(key: string): CustomPropertyType {
    return <CustomPropertyType>this._customTypes.get(key);
  }

  /**
   * @name resetCustomTypes
   * @description
   * Clears all entries in the customTypes map.
   * @memberof CustomPropertyService
   */
  resetCustomTypes(): void {
    this._customTypes.clear();
  }
}
