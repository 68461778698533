/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TemplateContextType {
    MissionByTicketDescription = 1,
    MissionByTaskDescription = 2,
    NoteForTicket = 3,
    NoteForMissionByTicket = 4,
    NoteForMissionByTask = 5,
    MissionDescription = 6,
    NoteForMission = 9,
    NoteBase = 11,
    MissionNotificationContent = 12,
    TicketNotificationContent = 13,
    InfoNotificationContent = 14,
    MissionNotificationSubject = 15,
    TicketNotificationSubject = 16,
    InfoNotificationSubject = 17,
    IssueEmailSubject = 18,
    IssueEmailContent = 19,
    MessageIssueEmailSubject = 181,
    TicketIssueEmailSubject = 182,
    MissionIssueEmailSubject = 183,
    WinterhalterIssueEmailSubject = 184,
    MessageMailNodeEmailSubject = 185,
    TicketMailNodeEmailSubject = 186,
    WinterhalterMailNodeEmailSubject = 187,
    MessageIssueEmailContent = 191,
    TicketIssueEmailContent = 192,
    MissionIssueEmailContent = 193,
    WinterhalterIssueEmailContent = 194,
    MessageMailNodeEmailContent = 195,
    TicketMailNodeEmailContent = 196,
    WinterhalterMailNodeEmailContent = 197,
}
