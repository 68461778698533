import {Injectable} from "@angular/core";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {IssueDtoStereotypeType, IssueDtoType} from "@shared/components/issues/types/issue-dto.type";

@Injectable({
  providedIn: 'root',
})
export abstract class IssueBaseStereotypeService extends IssuesBaseService {

  isIssueDtoStereotypeType(data: IssueDtoType): data is IssueDtoStereotypeType {
    return (
      'stereotypeId' in data &&
      'customPropertyValues' in data
    );
  }
}
