import {Injectable} from "@angular/core";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto,
  IssueResourceSelectorKind, IssueType,
  PrioritySimpleDto,
  ResourceProfileDto, StereotypeDto,
  StereotypeUsageDto,
  SubmitTicketIssueDto,
  TicketIssueDto,
  TicketIssuePreviewDto
} from "@server-models";
import {
  CustomPropertyStructureFormatted
} from "@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {IssueBaseStereotypeService} from "@shared/components/issues/services/stereotype/stereotype.service";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";
import {
  AdditionalDetails
} from "@shared/components/custom-property/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseTicketService extends IssueBaseStereotypeService {

  override formatReadDetailData(issueDtoType: IssueDtoType, issueType: IssueType, stereotypeDto?: StereotypeDto | undefined): CustomPropertyStructureFormatted {
    const issueDtoTypeTicket = issueDtoType as TicketIssueDto;
    return this._stereotypeBaseService.customPropertiesFormattedFromStereotype(
      issueDtoTypeTicket.issueId!,
      issueDtoTypeTicket.title!,
      issueType,
      issueDtoTypeTicket.customPropertyValues!,
      stereotypeDto!,
      issueDtoTypeTicket,
      (dto) => this.getAdditionalFormattedData(dto)
    );
  }

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: TicketIssuePreviewDto, resourceDto?: ResourceProfileDto): SubmitTicketIssueDto {
    const priorityFormGroup = formGroup.get('Priority') as FormGroup;
    const customValuesFromGroup = formGroup.get('CustomValues') as FormGroup;

    const priority = this.findPriorityValue(priorityFormGroup, templatePreviewDto.priorities!);

    const customPropertyValues = this._stereotypeBaseService.prepareCustomValues(customValuesFromGroup); // to build the customValues
    const issueStereotype: StereotypeUsageDto = templatePreviewDto.issueStereotype!;
    const customPropertySets: CustomSetReferenceDto[] = issueStereotype.customPropertySets!;

    const sortedCustomPropertySets: CustomSetReferenceDto[] = this._stereotypeBaseService.sortCustomPropertySets(customPropertySets);
    const stereotypeCustomProperties: CustomPropertyDto[] = this._stereotypeBaseService.flattenCustomProperties(sortedCustomPropertySets);
    const mutateCustomPropertyValues: FilledCustomValueDto[] = this._stereotypeBaseService.prepareCustomPropertyValues(customPropertyValues, issueStereotype.tenantId!, stereotypeCustomProperties);

    const resourceKind = this._checkResourceKind(templatePreviewDto.resourceSelectorKind!) ? resourceDto : undefined;

    return {
      title: templatePreviewDto.name,
      tenantId: issueStereotype?.tenantId,
      stereotypeId: issueStereotype?.stereotypeId,
      stereotypeRowVersion: issueStereotype?.rowVersion,
      customPropertyValues: mutateCustomPropertyValues,
      priority,
      resource: resourceKind
    };
  }

  _checkResourceKind(resourceOption: IssueResourceSelectorKind): boolean {
    let displayResource = true;
    if (resourceOption === IssueResourceSelectorKind.Selection) {
      displayResource = true;
    } else if (resourceOption === IssueResourceSelectorKind.None) {
      displayResource = false;
    }
    return displayResource;
  }

  findPriorityValue(priorityForm: FormGroup, priorities: PrioritySimpleDto[]): PrioritySimpleDto {
    const valueToFind = priorityForm.value;
    return priorities.find(p => p?.priorityId === valueToFind)!
  }

  getAdditionalFormattedData(issueDto: TicketIssueDto): Partial<AdditionalDetails> {
    const ticketDetail = issueDto.ticket;
    return {
      ticket: ticketDetail
    };
  }
}
