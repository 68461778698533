/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum IssueReasonType {
    Base = '0',
    ReachedFixedTime = 1,
    ReachedError = 2,
    ReachedConsumption = 3,
    ReceivedMail = 4,
}
