/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ControlConditionType {
    Base = '0',
    FixedTime = 2,
    Consumption = 3,
    Error = 5,
}
