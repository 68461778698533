import {Injectable} from "@angular/core";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {
  IssueType,
  ResourceProfileDto,
  SubmitWinterhalterProductDto,
  SubmitWinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssuePreviewDto,
} from "@server-models";
import {
  CustomPropertyStructureFormatted
} from "@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {
  AdditionalDetails
} from "@shared/components/custom-property/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseWinterhalterProductOrderService extends IssuesBaseService {
  override formatReadDetailData(issueDtoType: WinterhalterProductOrderIssueDto, issueType: IssueType): CustomPropertyStructureFormatted {
    const baseResult = <CustomPropertyStructureFormatted>{
      structureId: issueDtoType.issueId,
      headerTitle: issueDtoType.title,
      type: issueType
    }

    return {
      ...baseResult,
      additionalDetails: {...this.getAdditionalFormattedData(issueDtoType)}
    };
  }

  override prepareFormIssueDto(form: FormGroup, templatePreviewDto: WinterhalterProductOrderIssuePreviewDto, resourceDto?: ResourceProfileDto, tenantId?: number): SubmitWinterhalterProductOrderIssueDto {
    const formValues = form.value;
    const submitWinterhalterProductDtoList = (Object.values(formValues) as SubmitWinterhalterProductDto[]);
    const combinedProducts = submitWinterhalterProductDtoList.map(({productId, quantity}) => ({
        productId,
        quantity
      }));
    return {
      title: templatePreviewDto.name,
      tenantId,
      products: combinedProducts,
      resource: resourceDto
    };
  }

  override getAdditionalFormattedData(issueDto: WinterhalterProductOrderIssueDto): Partial<AdditionalDetails> {
    return {
      winterhalter: {
        winterhalterOrder: issueDto?.winterhalterOrder,
        productOrder: {
          resource: issueDto.resource,
          orderId: issueDto.winterhalterOrder!.orderId,
          formData: {
            productList: issueDto.products!
          }
        }
      }
    };
  }
}
