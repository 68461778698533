  <ng-container
    [ngSwitch]="headerDesign"
    *ngFor="let propertySets of propertySetsDetail; let setsIndex = index"
  >
    <ion-card-header *ngSwitchCase="HeaderDesignEnum.Header">{{ propertySets.name }}</ion-card-header>
    <ion-card-title *ngSwitchCase="HeaderDesignEnum.Title" color="dark">{{ propertySets.name }}</ion-card-title>
    <ng-container *ngFor="let issue of propertySets.customPropertiesFormatted; let issueIndex = index">
      <app-custom-property-read [title]="issue.title" [inputProperty]="issue"></app-custom-property-read>
    </ng-container>
  </ng-container>

