import {Injectable} from "@angular/core";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {
  IssueType,
  ResourceProfileDto,
  SubmitWinterhalterServiceCallIssueDto,
  WinterhalterServiceCallIssueDto, WinterhalterServiceCallIssuePreviewDto,
} from "@server-models";
import {
  CustomPropertyStructureFormatted
} from "@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {
  AdditionalDetails
} from "@shared/components/custom-property/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseWinterhalterServiceCallService extends IssuesBaseService {
  override formatReadDetailData(issueDtoType: WinterhalterServiceCallIssueDto, issueType: IssueType): CustomPropertyStructureFormatted {
    const baseResult = <CustomPropertyStructureFormatted>{
      structureId: issueDtoType.issueId,
      headerTitle: issueDtoType.title,
      type: issueType
    }

    return {
      ...baseResult,
      additionalDetails: {...this.getAdditionalFormattedData(issueDtoType)}
    };
  }

  override prepareFormIssueDto(form: FormGroup, templatePreviewDto: WinterhalterServiceCallIssuePreviewDto, resourceDto?: ResourceProfileDto, tenantId?: number): SubmitWinterhalterServiceCallIssueDto {
    const formValues = form.value;
    return {
      title: templatePreviewDto.name,
      tenantId,
      problem: formValues.problem,
      category: formValues.category,
      message: formValues.message,
      contactFirstName: formValues.contactFirstName,
      contactLastName: formValues.contactLastName,
      resource: resourceDto
    };
  }

  override getAdditionalFormattedData(issueDto: WinterhalterServiceCallIssueDto): Partial<AdditionalDetails> {
    return {
      winterhalter: {
        winterhalterOrder: issueDto?.winterhalterOrder,
        serviceCall: {
          resource: issueDto.resource,
          orderId: issueDto.winterhalterOrder!.orderId,
          formData: {
            category: issueDto.category,
            problem: issueDto.problem,
            message: issueDto.message,
            contactFirstName: issueDto.contactFirstName,
            contactLastName: issueDto.contactLastName
          }
        }
      }
    };
  }
}
